/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/dist/styles/ag-grid.css';
/* @import 'ag-grid-community/dist/styles/ag-theme-balham.css'; */
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@font-face {
    font-family: "Inv Eina 03";
    src: url("../src/assets/fonts/Inv-Eina03-SemiBold.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-Eina03-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-Book.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-Book.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-BookItalic.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-BookItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-Medium.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-Medium.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-MediumItalic.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-MediumItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-Demi.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-Demi.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Inv Maison Neue";
    src: url("../src/assets/fonts/Inv-MaisonNeue-DemiItalic.woff2") format("woff2"),
        url("../src/assets/fonts/Inv-MaisonNeue-DemiItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: "Aktiv Grotesk";
    src: url("https://static.invisionapp-cdn.com/global/fonts/AktivGroteskVF_W_Wght.woff2")
      format("woff2-variations");
    font-weight: 125 950;
    font-stretch: 100%;
    font-style: normal;
  }

  @font-face {
    font-family: "Aktiv Grotesk";
    src: url("../src/assets/fonts/AktivGroteskVF_Italics_W_Wght.woff2")
      format("woff2-variations");
    font-weight: 125 950;
    font-stretch: 100%;
    font-style: italic;
  }
  body #invision-frame-container {
    display: none;
  }
  body.feature-is-framed #invision-frame-container {
    display: block;
  }
  #invision-frame-container iframe {
    display: none; 
    width: 100%; 
    height: 100%; 
    border: none; 
    position: absolute; 
    left: 0px; 
    right: 0px; 
    bottom: 0px; 
    top: 0px;
    opacity:0;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* .mat-slide-toggle-bar {
  background-color: #e47171 !important;
} */
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgb(105, 105, 241)!important;
}

 .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: blue!important;
}
.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: #FD93B7!important;
}

 .mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #FF4081!important;
}
.closeIcon{
  float:right;
  font-size:1.5em;
  color:white;
  background: linear-gradient(#0984A8, #1FAE79);
  padding-inline:6px;
  padding-block:3px;
  border-radius:3px;
  cursor:pointer;
  font-weight: 500;
  -webkit-box-shadow: 0 0 10px rgb(202, 200, 200);
        box-shadow: 0 0 10px rgb(202, 200, 200);
}
.online{
  display:inline-block;
  width:10px;
  height: 10px;
  border-radius: 50px;
  background:rgb(33, 134, 39);
  margin-left:5px;
  margin-top:-10px;
}
.offline{
display:inline-block;
width:10px;
height: 10px;
border-radius: 50px;
background:rgb(248, 54, 54);
margin-left:5px;
margin-top:-10px;
}
.mat-dialog-container{
  /* padding-inline:0px!important;
  padding-block: 24px !important; */
  border-radius: 10px!important;
}
/* .custom-modalbox .mat-dialog-container {
    padding-inline: 24px !important;
  } */
/* .custom-modalbox{
  .mat-dialog-container{
   padding: 24px !important;
}
} */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical {
  /* background: #b0b1af; */
  background: rgb(70, 62, 62);
  border-radius: 100px;
  /* border-top:10px solid black;
  border-bottom:10px solid black; */
}
::-webkit-scrollbar-thumb:horizontal {
  background: #b0b1af;
  border-radius: 100px;
}

html {
scrollbar-color: #889e39;
scrollbar-width: thin;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:rgb(51, 124, 240); 
  background-color:rgb(51, 124, 240);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:rgb(51, 124, 240);
  background-color:rgb(51, 124, 240);
  display: none;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:rgb(255, 37, 37,.26);
}